<template>
  <div>
    <validation-observer
      ref="serviceForm"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col
          cols="12"
          md="5"
        >
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="serviceForm.name"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="5"
        >
          <validation-provider
            #default="validationContext"
            name="ArabicName"
            rules="required"
          >
            <b-form-group
              label="Arabic Name"
              label-for="ArabicName"
            >
              <b-form-input
                id="ArabicName"
                v-model="serviceForm.name_ar"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Arabic Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="auto"
        >
          <validation-provider
            #default="validationContext"
            name="IsActive"
            rules="required"
          >
            <b-form-group
              label="Activate"
              label-for="IsActive"
            >
              <b-form-checkbox
                id="IsActive"
                v-model="serviceForm.is_active"
                :value="1"
                :unchecked-value="0"
                class="custom-control-secondary"
                name="check-button"
                :state="getValidationState(validationContext)"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="addService"
          >
            Save
          </b-button>
          <b-button
            variant="outline-danger"
            class="closeModal"
            @click="closeModal"
          >
            Cancel
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import axios from 'axios'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import service from '../service'

export default {
  data() {
    return {
      required,
      serviceForm: {},
      id: this.$store.state.generalIds.id,
      loader: false,
      validationErrors: {},
      errors: {},
    }
  },
  setup() {
    const { getValidationState } = formValidation()

    const { serviceForm } = service()

    return {
      getValidationState,
      serviceForm,
    }
  },
  mounted() {
    this.viewService()
  },
  methods: {
    closeModal() {
      this.serviceForm = {
        name: '',
        name_ar: '',
        is_active: 0,
      }
      this.$store.commit('generalIds/SET_ID', null)
      this.$bvModal.hide('service')
    },
    addService() {
      if (this.id) {
        this.loader = true
        axios.put(`services/${this.id}`, this.serviceForm).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$parent.$parent.$parent.$refs.servicesTable.getAllData()
            this.closeModal()
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true
        axios.post('services', this.serviceForm).then(res => {
          this.$parent.$parent.$parent.$refs.servicesTable.getAllData()
          if (res.status === 200 || res.status === 201) {
            this.closeModal()
            this.$toasted.show('Added Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          this.errors = error?.response?.data?.errors
        }).finally(() => {
          this.loader = false
        })
      }
    },
    viewService() {
      if (this.id) {
        axios.get(`services/${this.id}`).then(res => {
          if (res.status === 200) {
            this.serviceForm = res.data?.data
          }
        })
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
