import { ref } from '@vue/composition-api'

export default function service() {
  const serviceForm = ref({
    name: '',
    name_ar: '',
    is_active: 0,
  })

  const viewData = ref({})

  return {
    serviceForm,
    viewData,
  }
}
